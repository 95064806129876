// Add your JS customizations here
jQuery(function($){
    $(window).scroll(function() {
      if ($(this).scrollTop() > 50) { // If scrolled more than 50px
        $('#main-nav').addClass('scrolled');
      } else {
        $('#main-nav').removeClass('scrolled');
      }
    });
  
  })

  jQuery(function($){
    function moveDiv() {
        if ($(window).width() < 1125) {
            $('.menu--details').appendTo('ul#mega-menu-primary');
            
            
        } else{
            $('.menu--details').appendTo('.navbar>.container');
        }
    }
    moveDiv();
    $(window).resize(moveDiv);
    
    })